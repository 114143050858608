.signup {
  @apply
  flex
  flex-col
  /* flex-grow */
  justify-start
  items-center
  p-4
  w-full
  m-auto
  

  bg-gray-100
  rounded-md
  shadow-md

  sm:max-w-xl
  sm:ml-auto
  sm:mr-auto
}


.signup--header {
  @apply
  flex
  flex-col
  gap-2
  text-center
  justify-center
  items-center
  pb-6
}

.signup--header--logo {
  @apply
  flex
  flex-shrink-0
  bg-contain
  bg-center
  bg-no-repeat
  h-16
  w-16
}

.signup--form {
  @apply
  flex
  flex-col
  /* flex-grow */
  gap-4
}

.signup--form--personal {
  @apply
  flex
  flex-wrap
  justify-center
  gap-4
}

.signup--footer,
.divider {
  @apply
  flex
  flex-col
  w-full
  gap-2
  border-t
  pt-4
  mt-4
  border-solid
  border-gray-300
}

.signup--form--item {
  @apply
  flex
  flex-col
  flex-1
}

.full {
  @apply
  flex
  flex-grow
  gap-4
}

.signup--alerts {
  @apply
  flex
  flex-grow
  justify-start
  items-start
  bg-red-700
  text-white
  font-bold
  pl-4
  pr-4
  rounded-md
  text-sm
  text-left
}







