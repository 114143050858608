.login {
  @apply
  flex
  flex-col
  flex-grow
  justify-center
  items-center
  m-auto
  p-10
  gap-10
  bg-white

  sm:flex-row
}

.login--logo {
  @apply
  flex
  flex-col
  justify-center
  gap-4
}

.login--logo--img {
  @apply
  flex
  flex-shrink-0
  bg-contain
  bg-no-repeat
  bg-center
  h-32
}

.login--logo--text {
  @apply
  flex
  text-gray-700
  font-normal
  text-lg
}

.login--input {
  @apply
  flex
  flex-col
  p-10
  bg-gray-100
  rounded-md
  shadow-md
  max-w-md
  w-full
}

.login--input--signin {
  @apply
  flex
  flex-col
  gap-2

}

.login--input--signin--email,
.login--input--signin--password {
  @apply
  p-4
  rounded-md
}


.login--input--separator {
  @apply
  border-t
  border-solid
  border-gray-400
  mt-6
  mb-6
}

.login--input--signup {
  @apply
  flex
  flex-col
  gap-1
}

.login--message {
  @apply
  p-2
  bg-red-600
  rounded-md
  mt-2
  mb-2
  text-white
}

.login--message--off {
  @apply
  hidden
}


.login--forgot {
  @apply
  italic
  cursor-pointer
  underline
}


