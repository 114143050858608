@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  line-height: 1;
}


body {
  @apply bg-gray-100
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

.App {
  @apply
  flex
  flex-col
  flex-grow
  max-h-screen
  h-screen
  w-screen
  bg-white
}

.text-header {
  @apply
  font-bold
  text-lg
  uppercase
}

.label {
  @apply
  pl-2
  text-base
  font-normal
  italic
  text-gray-500
}

.text-body {
  @apply
  font-normal
  text-base
  normal-case
  text-gray-500
}

input::placeholder {
  @apply
  italic
}


.button {
  @apply
  flex
  justify-center
  items-center
  rounded-md
  text-white
  p-2
  bg-cyan-500
  border
  border-solid
  border-cyan-500
  uppercase
  font-bold

  hover:bg-white
  hover:text-cyan-500

  cursor-pointer
}

input {
  @apply
  p-2
  rounded-md
  bg-white
}



