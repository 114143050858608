.budget {
  @apply
  flex
  flex-col
  flex-grow
  sm:pl-10
  sm:pr-10
}

.budget--new {
  @apply
  flex
  justify-between
  gap-4
}

.budget--new--button {
  @apply
  flex
  gap-2
  pl-4
  pr-4
  rounded-md
  bg-cyan-700
  items-center
  shadow-sm
  text-white
  cursor-pointer
}


.layout--navbar--search input {
  @apply
  bg-gray-100
}

.budget--items {
  @apply
  flex 
  flex-wrap
  justify-between
  gap-4
  pt-4
  sm:pt-10
}

.budget--item {
  @apply
  flex
  flex-col
  flex-grow
}

.budget--item--input {
  @apply
  bg-gray-100
}

.budget--items .button {
  @apply
  flex
  flex-1;
  flex-basis: 100%;
}

.budget--item--row {
  @apply
  flex
  flex-col
  justify-between
  mt-4
  gap-4
  p-2
  pl-4
  pr-4
  rounded-md
  sm:gap-10
  sm:flex-row
}

.budget--item--row:nth-child(even){
  @apply
  bg-gray-100
}

.budget--item--row--user {
  @apply
  flex
  flex-col
}

.budget--item--row--user--item {
  @apply
  flex
  items-center
  gap-2
  cursor-pointer
}

.budget--item--row--place,
.budget--item--row--budget {
  @apply
  flex
  flex-col
  flex-grow
}

.budget--item--row--budget--buttons {
  @apply
  flex
  w-full
  mt-4
  cursor-pointer
}

.budget--item--row--budget--buttons .button {
  @apply
  bg-red-700
  border-red-700
  hover:text-red-700
  hover:bg-white
}










